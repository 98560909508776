import { wrapRequest } from 'utils/ajax';
import { scheduleCRUD } from './cruds';

export const getScheduleAssignment = osceId =>
  scheduleCRUD.getList({
    additionalUrl: `osce/${osceId}`,
  });

export const generateSchedule = osceId =>
  wrapRequest({
    method: 'POST',
    url: `/schedule/osce/${osceId}`,
  });

export const assignStudents = (osceId, studentAssignOrder) =>
  wrapRequest({
    method: 'POST',
    url: '/student/assign',
    data: {
      osceId,
      studentAssignOrder,
    },
  });

export const swapStudents = data =>
  wrapRequest({
    method: 'POST',
    url: '/student/swap',
    data,
  });

export const assignExaminer = data =>
  wrapRequest({
    method: 'PUT',
    url: '/examiner/assign',
    data,
  });

export const assignSP = osceId =>
  wrapRequest({
    method: 'PUT',
    url: `/patient/assign/osce/${osceId}`,
  });

export const updateSP = osceId =>
  wrapRequest({
    method: 'PUT',
    url: `/patient/assign/update/osce/${osceId}`,
  });

export const swapSP = data =>
  wrapRequest({
    method: 'POST',
    url: `/patient/swap`,
    data,
  });

export const changeDuration = data =>
  wrapRequest({
    method: 'PUT',
    url: `/change-duration`,
    data,
  });

export const clearAllSchedule = osceId =>
  wrapRequest({
    method: 'DELETE',
    url: `/schedule/osce/${osceId}/clearAll`,
  });

export const exportStudent = (id, type) =>
  wrapRequest({
    method: 'POST',
    url: `/schedule/osce/${id}/xls/${type}`,
    responseType: 'arraybuffer',
  });

export const getScheduleOsceDay = OsceDayId =>
  wrapRequest({
    method: 'GET',
    url: `/schedule/osceDay/${OsceDayId}`,
  });
