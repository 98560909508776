import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox, Tooltip } from 'antd';

import i18n from 'services/i18n';
import { wait } from 'services/promise';

const FormItem = Form.Item;

/**
 * Component FormSingleCheckbox used for check boxes
 * @reactProps {obj} form - form object
 * @reactProps {func} remoteSubmit - remote submit function
 * @reactProps {boolean} disabled - disable
 */
@i18n('form')
export default class FormSingleCheckbox extends Component {
  static contextTypes = {
    form: PropTypes.object,
    remoteSubmit: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    i18Name: PropTypes.string,
    initialValue: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  onChange = async () => {
    await wait();
    this.context.remoteSubmit();
  };

  render() {
    const { getFieldDecorator } = this.context.form;
    const { props } = this;
    return (
      <React.Fragment>
        <FormItem>
          {getFieldDecorator(props.name, {
            valuePropName: 'checked',
            initialValue: props.initialValue,
          })(
            <Tooltip
              placement="top"
              title={props.translate('isFormative', 'tooltips')}
            >
              <Checkbox
                style={{ margin: 0 }}
                // onChange={this.onChange}
                disabled={this.props.disabled || this.context.disabled}
              >
                {props.translate(props.label || props.name, props.i18Name)}
              </Checkbox>
            </Tooltip>,
          )}
        </FormItem>
      </React.Fragment>
    );
  }
}
