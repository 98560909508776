import { get } from 'lodash';

export const rolesAdminMapper = (array, localizate) =>
  array.map(item => ({
    value: item.id,
    label: localizate(item.adminRoleType, 'rights'),
  }));

export const defaultCheckBoxMapper = (array, localizate) =>
  array.map(name => ({
    value: name,
    label: localizate(name),
  }));

export const swap = (arr, oldIndex, newIndex) => {
  const temp = arr[oldIndex];
  arr.splice(oldIndex, 1);
  arr.splice(newIndex, 0, temp);
};

export const checkNewNotNull = (props, nextProps, name) =>
  get(nextProps, name) && get(props, name) !== get(nextProps, name);

export const checkArr = (func, arr) => (props, nextProps) =>
  arr.some(name => func(props, nextProps, name));

export const checkLoadProps = checkArr(checkNewNotNull, [
  'url',
  'sendParams',
  'sendData',
]);

export const checkLoadPropsName = (props, nextProps, name) =>
  checkArr(checkNewNotNull, name)(props, nextProps);

window.checkLoadProps = checkLoadProps;
