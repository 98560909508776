import React from 'react';
import { Col, Row } from 'antd';

import EditableLabel from 'components/Form/formEditableLabel';
import { checkPositiveNumber } from 'services/validations';
import DateLabel from 'components/Form/formEditableDatePicker';
import CheckBox from 'components/Form/formSingleCheckbox';
import { DATE_MARK } from 'constants/formats';

import i18n from 'services/i18n';

const checkPositive = {
  func: checkPositiveNumber,
  message: 'onlyPositiveNumber',
};

@i18n('form')
export default class TimeData extends React.Component {
  render() {
    let { data } = this.props;
    data = data || {};
    return (
      <Row gutter={32} className="basic-data-block">
        <Col span={8}>
          <DateLabel
            outputFormat={DATE_MARK}
            name="osceDate"
            initialValue={data.osceDate}
            disabled={this.props.data.freezed}
          />
          <DateLabel
            type="time"
            name="startTime"
            initialValue={data.startTime}
            disabled={this.props.data.freezed}
          />
          <DateLabel
            type="time"
            name="endTime"
            initialValue={data.endTime}
            disabled={this.props.data.freezed}
          />
          <EditableLabel
            name="stationDuration"
            initialValue={data.stationDuration}
            min={0}
            type="number"
            validator={checkPositive}
            disabled={this.props.data.freezed}
          />
          <CheckBox
            name="spStaysAtStation"
            initialValue={data.spStaysAtStation}
            disabled={this.props.data.freezed}
          />
        </Col>
        <Col span={8}>
          <EditableLabel
            name="shortBreak"
            initialValue={data.shortBreak}
            min={0}
            type="number"
            validator={checkPositive}
            disabled={this.props.data.freezed}
          />
          <EditableLabel
            name="lunchBreak"
            initialValue={data.lunchBreak}
            min={0}
            type="number"
            validator={checkPositive}
            disabled={this.props.data.freezed}
          />
          <EditableLabel
            name="rotationBreak"
            initialValue={data.rotationBreak}
            min={0}
            type="number"
            validator={checkPositive}
            disabled={this.props.data.freezed}
          />
          <EditableLabel
            name="coffeeBreak"
            initialValue={data.coffeeBreak}
            min={0}
            type="number"
            validator={checkPositive}
            disabled={this.props.data.freezed}
          />
        </Col>
        <Col span={8}>
          <EditableLabel
            name="maxTimeUntilLunchBreak"
            initialValue={data.maxTimeUntilLunchBreak}
            min={0}
            type="number"
            validator={checkPositive}
            disabled={this.props.data.freezed}
          />
          <EditableLabel
            name="maxTimeUntilLongBreak"
            initialValue={data.maxTimeUntilLongBreak}
            min={0}
            type="number"
            validator={checkPositive}
            disabled={this.props.data.freezed}
          />
        </Col>
      </Row>
    );
  }
}
