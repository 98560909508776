import React, { Component } from 'react';
import { get } from 'lodash';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';

import EditableLabel from 'components/Form/formEditableLabel';
import GroupCheckbox from 'components/Form/formGroupCheckbox';
import i18n from 'services/i18n';
import { getInitialValueForCheckBoxGroup } from 'services/form';
import { defaultCheckBoxMapper } from 'services/mapReduce';
import EditableLabelDropDown from 'components/Form/formEditableLabelDropDown';
import Card from 'components/Form/Card';
import { checkPositiveNumber } from 'services/validations';

const checkBoxNames = ['nextExaminee', 'reviewMode', 'autoSelection'];

const checkPositive = {
  func: checkPositiveNumber,
  message: 'onlyPositiveNumber',
};

@i18n('form')
export default class IOsceSettings extends Component {
  static contextTypes = {
    setStatus: PropTypes.func,
  };

  get timeEnum() {
    return [
      {
        value: 'SECOND',
      },
      {
        value: 'MINUTE',
      },
    ];
  }

  get checkBoxInitialValues() {
    return getInitialValueForCheckBoxGroup(checkBoxNames, this.props.data);
  }

  get groupCheckboxOptions() {
    return defaultCheckBoxMapper(checkBoxNames, this.props.translate);
  }

  onChangeCheckBox = async () => {
    await this.context.setStatus();
    this.props.submit();
  };

  render() {
    const { data } = this.props;

    return (
      <Card from="cards" value="iOsceSettings" className="osce-settings-card">
        <Row gutter={48}>
          <Col span={12}>
            <EditableLabel
              name="backUpPeriod"
              i18name="iOsceSettings"
              initialValue={get(data, 'backUpPeriod')}
              type="number"
              validator={checkPositive}
            />
            <EditableLabelDropDown
              name="timeUnit"
              i18name="iOsceSettings"
              options={this.timeEnum}
              initialValue={get(data, 'timeUnit')}
            />
            <EditableLabel
              name="settingPassword"
              i18name="iOsceSettings"
              initialValue={get(data, 'settingPassword')}
            />
          </Col>
          <Col span={12}>
            <GroupCheckbox
              name="checkBox"
              options={this.groupCheckboxOptions}
              initialValue={this.checkBoxInitialValues}
              onChange={this.onChangeCheckBox}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <EditableLabel
              name="screenSaverTime"
              type="number"
              i18name="iOsceSettings"
              initialValue={get(data, 'screenSaverTime')}
              validator={checkPositive}
            />
            <EditableLabel
              name="webServicePath"
              i18name="iOsceSettings"
              initialValue={get(data, 'webServicePath')}
            />
            <EditableLabel
              name="registerDevicePath"
              i18name="iOsceSettings"
              initialValue={get(data, 'registerDevicePath')}
            />
          </Col>
        </Row>
        <Row>
          <EditableLabel
            type="textarea"
            name="screenSaverText"
            i18name="iOsceSettings"
            initialValue={get(data, 'screenSaverText')}
          />
        </Row>
      </Card>
    );
  }
}
