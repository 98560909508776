import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

/** UI (Antd) */
import { Select, Spin } from 'antd';

/** Actions */
import { osceCRUD } from 'actions/cruds';

/** Services */
import stateSend from 'services/stateSend';

/** Constants*/
import { DEFAULT_SCROLL_OFFSET } from 'constants/common';

/**
 * Select with osces
 * @reactProps {func} onChange - change examiner
 * @reactProps {number} value - current selected examiner
 */
export default class OSCEsList extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { osces: { data: [] } };
    this.debouncedTryLoadMore = debounce(this.tryLoadMore, 100, {
      leading: false,
    });
    this.debouncedSearch = debounce(this.onSearch, 200, {
      leading: false,
    });
  }

  componentWillMount() {
    this.load({ page: 0 });
  }

  tryLoadMore = offset => {
    const { osces } = this.state;
    if (
      offset < DEFAULT_SCROLL_OFFSET &&
      this.checkLoadMore() &&
      !osces.search
    ) {
      this.load({ page: osces.pagination.page + 1 });
    }
  };

  checkLoadMore = () => {
    const { total } = this.state.osces.pagination;
    return !!total && this.state.osces.data.length < total;
  };

  load = async params => {
    const { osces } = this.state;
    params.semesterId = this.props.semesterId;
    const response = await stateSend(
      loading => this.setState({ loading }),
      osceCRUD.getList({ params }),
    );
    let data = [];
    if (params.search !== undefined) {
      data = [...response.data];
    } else {
      data = [...osces.data, ...response.data];
    }
    this.setState({
      osces: {
        data,
        pagination: response.pagination,
        search: params.search,
      },
    });
  };

  onScroll = e => {
    const { target } = e;
    this.debouncedTryLoadMore(
      target.scrollHeight - target.offsetHeight - target.scrollTop,
    );
  };

  onSearch = search => {
    this.load({ page: 0, search });
  };

  onChange = osceName => {
    const selectedOsce = this.state.osces.data.find(
      item => item.name === osceName,
    );
    this.props.onChange(selectedOsce.id);
  };

  render() {
    const { osces } = this.state;
    return (
      <Select
        showSearch
        style={{ width: '120px' }}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onSearch={this.debouncedSearch}
        onPopupScroll={this.onScroll}
        onSelect={this.onChange}
        value={this.props.value}
        notFoundContent={
          this.state.loading ? <Spin size="small" /> : 'Not found'
        }
      >
        {osces.data.map(item => (
          <Select.Option key={item.id} value={item.name}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    );
  }
}
