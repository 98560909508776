import React, { Component } from 'react';
import { Switch, Row, Col } from 'antd';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { wrapRequest } from 'utils/ajax';
import i18n from 'services/i18n';
import addLoad from 'services/decorators/addLoad';
import Card from 'components/Form/Card';
import { updateData } from 'actions/updateData';
import { DEFAULT_DESKTOP_SIZE } from '../../../constants';

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators({ updateData }, dispatch);

const mapStateToProsp = ({ runtime }) => ({
  data: runtime.osceCustomData,
  loaded: runtime.osceCustomLoaded,
});

@i18n('form')
@withRouter
@addLoad()
@connect(
  mapStateToProsp,
  mapDispatchToProps,
)
export default class OsceSettings extends Component {
  changeStatus = async activateCustom => {
    await this.props.stateloading(
      wrapRequest({
        method: 'POST',
        url: `/sftp/${this.props.match.params.id}/mode`,
        data: {
          activateCustom: !activateCustom,
        },
      }),
    );
    this.props.getSettingsProfile();
  };

  render() {
    const { props } = this;
    const opt =
      window.screen.width < DEFAULT_DESKTOP_SIZE ? { size: 'small' } : {};
    return (
      <Card
        from="form"
        value="osceSettings"
        className="osce-settings-card osce-settings-card_border osce-settings-card_custom-header"
      >
        <Row>
          <Col span={12}>
            <Row className="card-item">
              <div className="ant-col-4 ant-form-item-label">
                <label htmlFor="isActive">
                  {props.translate('useDefaultSettings')}
                </label>
              </div>
              <div className="ant-col-14 ant-form-item-control-wrapper switcher-wrapper">
                <Switch
                  {...opt}
                  checked={props.loaded && !get(props, 'data.isActive')}
                  onChange={this.changeStatus}
                  disabled={get(this.props, 'osceData.freezed')}
                />
              </div>
            </Row>
          </Col>
        </Row>
      </Card>
    );
  }
}
