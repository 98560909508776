import axios from 'axios';
import qs from 'qs';
import { history } from 'store';
import { SERVER_URL } from '../constants/global';

// HttpClient config
const httpClient = axios.create({
  baseURL: SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
});

httpClient.interceptors.request.use(
  config => {
    const token = `Bearer ${localStorage.getItem('token')}`;
    config.headers.Authorization = token;
    return config;
  },
  err => Promise.reject(err),
);

httpClient.interceptors.response.use(
  res => res,
  err => {
    if (err.response) {
      const { status } = err.response;

      if (status >= 401 && status <= 403) {
        localStorage.removeItem('token');
        history.push('/auth/login');
      }
    }

    return Promise.reject(err);
  },
);

// Think of this as a factory that provides HTTP methods for endpoints
// and in the same time creates separation between the actual HTTP client and
// other layers that are using it
class API {
  constructor() {
    this.client = httpClient;
  }

  getAllQeustionsBySp = id => {
    if (!id) return Promise.reject(new Error('Id is required.'));
    return this.client.get(`/question/${id}`);
  };

  getAllQuestions = ({
    search,
    page,
    pageSize,
    sortProp,
    sortDirection = 'ASC',
  }) => {
    if (typeof search === 'undefined')
      return Promise.reject(new Error('Search is required.'));
    if (typeof page === 'undefined')
      return Promise.reject(new Error('Page is required.'));
    if (typeof pageSize === 'undefined')
      return Promise.reject(new Error('Page size is required.'));

    return this.client.get('/question', {
      params: { search, page, pageSize, sortProp, sortDirection },
    });
  };

  createNewQuesiton = data => {
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.post('/question', data);
  };

  updateQuestion = (id, data) => {
    if (!id) return Promise.reject(new Error('Question id is required.'));
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.put(`/question/${id}`, data);
  };

  deleteQuestion = id => {
    if (!id) return Promise.reject(new Error('Question id is required.'));
    return this.client.delete(`/question/${id}`);
  };

  getQuestionsGroupedBySection = ({
    search,
    sortProp,
    sortDirection = 'ASC',
  }) => {
    if (typeof search === 'undefined')
      return Promise.reject(new Error('Search is required.'));
    return this.client.get('/question/grouped/section', {
      params: { search, sortProp, sortDirection },
    });
  };

  postAnswer = data => {
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.post('/answer', data);
  };

  postCommentToAnswer = data => {
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.patch('/answer/comment', data);
  };

  postCommentToAnswerOption = data => {
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.patch('/answer/option/comment', data);
  };

  deleteAnswer = data => {
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.delete('/answer', { data });
  };

  getAllRoles = ({ search, page, pageSize, skillId }) => {
    if (typeof search === 'undefined')
      return Promise.reject(new Error('Search is required.'));
    if (typeof page === 'undefined')
      return Promise.reject(new Error('Page is required.'));
    if (typeof pageSize === 'undefined')
      return Promise.reject(new Error('Page size is required.'));

    return this.client.get('/role', {
      params: { search, page, pageSize, skillId },
    });
  };

  getRoleInfo = id => {
    if (!id) return Promise.reject(new Error('Role id is required.'));
    return this.client.get(`/role/${id}`);
  };

  createNewRole = data => {
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.post('/role', data);
  };

  updateRole = (id, data) => {
    if (!id) return Promise.reject(new Error('Role id is required.'));
    if (!data) return Promise.reject(new Error('Data is required.'));
    const {
      isUsedInOsce,
      acronym,
      roleDesignation,
      roleType,
      statisticFactor,
      absoluteNumberOfBackupSPs,
      skillId,
    } = data;

    return this.client.put(`/role/${id}`, {
      isUsedInOsce,
      roleInformationRequest: {
        acronym,
        roleDesignation,
        roleType,
        statisticFactor,
        absoluteNumberOfBackupSPs,
      },
      skillId,
    });
  };

  getAllRolesOSCEs = ({
    roleId,
    page,
    pageSize,
    sortProp,
    sortDirection = 'ASC',
  }) => {
    if (!roleId) return Promise.reject(new Error('Role id is required.'));
    if (typeof page === 'undefined')
      return Promise.reject(new Error('Page is required.'));
    if (typeof pageSize === 'undefined')
      return Promise.reject(new Error('Page size is required.'));

    return this.client.get(`/role/${roleId}/patients`, {
      params: { page, pageSize, sortProp, sortDirection },
    });
  };

  printRole = (id, data) => {
    if (!id) return Promise.reject(new Error('Role id is required.'));
    if (!data) return Promise.reject(new Error('Data is required.'));

    return this.client.get(`/role/${id}/print`, {
      headers: {
        'Content-Type': 'application/pdf',
      },
      params: data,
      responseType: 'arraybuffer',
      transformResponse: [
        function(data) {
          return new Blob([data], { type: 'application/pdf' });
        },
      ],
    });
  };

  deleteRole = id => {
    if (!id) return Promise.reject(new Error('Role id is required.'));
    return this.client.delete(`/role/${id}`);
  };

  printRoleChecklist = id => {
    if (!id) return Promise.reject(new Error('Role id is required.'));
    return this.client.get(`/role/${id}/preview/printchecklist`, {
      responseType: 'arraybuffer',
    });
  };

  getAllRolesCriteria = (id, { page, pageSize }) => {
    if (!id) return Promise.reject(new Error('Role id is required.'));
    if (typeof page === 'undefined')
      return Promise.reject(new Error('Page is required.'));
    if (typeof pageSize === 'undefined')
      return Promise.reject(new Error('Page size is required.'));
    return this.client.get(`role/${id}/criteria`, {
      params: { page, pageSize },
    });
  };

  createRoleCriterion = (roleId, data) => {
    if (!roleId) return Promise.reject(new Error('Role id is required.'));
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.post(`/role/${roleId}/criteria`, data);
  };

  updateRoleCriterion = (roleId, criterionId, data) => {
    if (!roleId) return Promise.reject(new Error('Role id is required.'));
    if (!criterionId)
      return Promise.reject(new Error('Criterion id is required.'));
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.put(`/role/${roleId}/criteria/${criterionId}`, data);
  };

  deleteRoleCriterion = (roleId, criterionId) => {
    if (!roleId) return Promise.reject(new Error('Role id is required.'));
    if (!criterionId)
      return Promise.reject(new Error('Criterion id is required.'));
    return this.client.delete(`/role/${roleId}/criteria/${criterionId}`);
  };

  getAllSkills = ({
    disciplineId,
    page,
    pageSize,
    search,
    sortProp,
    sortDirection = 'ASC',
    sorted,
    unsorted,
  }) => {
    if (typeof page === 'undefined')
      return Promise.reject(new Error('Page is required.'));
    if (typeof pageSize === 'undefined')
      return Promise.reject(new Error('Page size is required.'));

    return this.client.get('/skill', {
      params: {
        disciplineId,
        page,
        pageSize,
        search,
        sortProp,
        sortDirection,
        orders: { sorted, unsorted },
      },
    });
  };

  createNewSkill = data => {
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.post('/skill', data);
  };

  updateSkill = (id, data) => {
    if (!id) return Promise.reject(new Error('Skill id is required.'));
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.put(`/skill/${id}`, data);
  };

  deleteSkill = id => {
    if (!id) return Promise.reject(new Error('Skill id is required.'));
    return this.client.delete(`/skill/${id}`);
  };

  getSkill = skillId => {
    if (!skillId) return Promise.reject(new Error('Skill id is required.'));
    return this.client.get(`/skill/${skillId}`);
  };

  getAllDisciplines = ({
    search,
    page,
    pageSize,
    sortProp,
    sortDirection = 'ASC',
  }) => {
    if (typeof search === 'undefined')
      return Promise.reject(new Error('Search is required.'));
    if (typeof page === 'undefined')
      return Promise.reject(new Error('Page is required.'));
    if (typeof pageSize === 'undefined')
      return Promise.reject(new Error('Page size is required.'));

    return this.client.get('/discipline', {
      params: { search, page, pageSize, sortProp, sortDirection },
    });
  };

  createDiscipline = data => {
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.post('/discipline', data);
  };

  updateDiscipline = data => {
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.put(`/discipline/${data.id}`, data);
  };

  deleteDiscipline = id => {
    if (!id) return Promise.reject(new Error('Id is required.'));
    return this.client.delete(`/discipline/${id}`);
  };

  getCheckList = id => {
    if (!id) return Promise.reject(new Error('Id is required.'));
    return this.client.get(`/checkList/${id}`);
  };

  createCheckListSection = (id, data) => {
    if (!id) return Promise.reject(new Error('Role id is required.'));
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.post(`/checkList/${id}/section`, data);
  };

  getAllCheckListSections = ({ idCheckList, page, pageSize }) => {
    if (!idCheckList)
      return Promise.reject(new Error('Checklist id is required.'));
    if (typeof page === 'undefined')
      return Promise.reject(new Error('Page is required.'));
    if (typeof pageSize === 'undefined')
      return Promise.reject(new Error('Page size is required.'));

    return this.client.get('/checkList/section', {
      params: { idCheckList, page, pageSize },
    });
  };

  deleteCheckListSection = sectionId => {
    if (!sectionId) return Promise.reject(new Error('Section id is required.'));
    return this.client.delete(`/checkList/section/${sectionId}`);
  };

  updateCheckListSection = data => {
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.put(`/checkList/section/${data.id}`, data);
  };

  importAllCheckListSections = data => {
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.post('/checkList/section/import', data);
  };

  checkListChangeQueueSection = data => {
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.post('/checkList/section/change/queue', data);
  };

  getCheckListQuestionsInSection = sectionId => {
    if (!sectionId) return Promise.reject(new Error('Section id is required.'));
    return this.client.get(`/checkList/question/${sectionId}`);
  };

  checkListChangeQueueQuestion = data => {
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.post('/checkList/question/change/queue', data);
  };

  createQuestionInSection = (sectionId, data) => {
    if (!sectionId) return Promise.reject(new Error('Section id is required.'));
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.post(`/checkList/${sectionId}/question`, data);
  };

  updateQuestionInSection = (questionId, data) => {
    if (!questionId)
      return Promise.reject(new Error('Question id is required.'));
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.put(`/checkList/question/${questionId}`, data);
  };

  importQuestionToSection = (sectionId, questionId) => {
    if (!sectionId) return Promise.reject(new Error('Section id is required.'));
    if (!questionId)
      return Promise.reject(new Error('Question id is required.'));
    return this.client.post(
      `checkList/${sectionId}/question/import/${questionId}`,
    );
  };

  deleteQuestionInSection = questionId => {
    if (!questionId)
      return Promise.reject(new Error('Question id is required.'));
    return this.client.delete(`/checkList/question/${questionId}`);
  };

  createSection = data => {
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.post('/section', data);
  };

  updateSection = (sectionId, data) => {
    if (!sectionId) return Promise.reject(new Error('Section id is required.'));
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.put(`/section/${sectionId}`, data);
  };

  deleteSection = sectionId => {
    if (!sectionId) return Promise.reject(new Error('Section id is required.'));
    return this.client.delete(`/section/${sectionId}`);
  };

  changeQueueSection = data => {
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.post('/section/change/queue', data);
  };

  changeQueueQuestion = data => {
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.post('/question/change/queue', data);
  };

  getAllProfessions = ({
    page,
    pageSize,
    search,
    sortProp,
    sortDirection = 'ASC',
  }) => {
    if (typeof page === 'undefined')
      return Promise.reject(new Error('Page is required.'));
    if (typeof pageSize === 'undefined')
      return Promise.reject(new Error('Page size is required.'));
    if (typeof search === 'undefined')
      return Promise.reject(new Error('Search is required.'));

    return this.client.get('/profession', {
      params: { page, pageSize, search, sortProp, sortDirection },
    });
  };

  createProfession = data => {
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.post(`/profession`, data);
  };

  deleteProfession = id => {
    if (!id) return Promise.reject(new Error('Id is required.'));
    return this.client.delete(`/profession/${id}`);
  };

  updateProfession = data => {
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.put(`/profession/${data.id}`, data);
  };

  getAllLanguages = ({
    page,
    pageSize,
    search,
    sortProp,
    sortDirection = 'ASC',
  }) => {
    if (typeof search === 'undefined')
      return Promise.reject(new Error('Search is required.'));
    if (typeof page === 'undefined')
      return Promise.reject(new Error('Page is required.'));
    if (typeof pageSize === 'undefined')
      return Promise.reject(new Error('Page size is required.'));

    return this.client.get('/language', {
      params: { page, pageSize, search, sortProp, sortDirection },
    });
  };

  getAllNationalities = ({
    page,
    pageSize,
    search,
    sortProp,
    sortDirection = 'ASC',
  }) => {
    if (typeof search === 'undefined')
      return Promise.reject(new Error('Search is required.'));
    if (typeof page === 'undefined')
      return Promise.reject(new Error('Page is required.'));
    if (typeof pageSize === 'undefined')
      return Promise.reject(new Error('Page size is required.'));

    return this.client.get('/nationality', {
      params: { page, pageSize, search, sortProp, sortDirection },
    });
  };

  createSimulatedPatient = data => {
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.post('/patient', data);
  };

  getSimulatedPatientsProfile = id => {
    if (!id) return Promise.reject(new Error('SPs id is required.'));
    return this.client.get(`/patient/${id}`);
  };

  updateSimulatedPatientsPersonalInfo = (id, data) => {
    if (!id) return Promise.reject(new Error('SPs id is required.'));
    if (!data) return Promise.reject(new Error('Data is required.'));
    return this.client.post(`/patient/${id}/personalInfo`, data);
  };

  getAllSimulatedPatientsOSCEs = ({
    id,
    page,
    pageSize,
    sortProp,
    sortDirection = 'ASC',
  }) => {
    if (!id) return Promise.reject(new Error('SPs id is required.'));
    if (typeof page === 'undefined')
      return Promise.reject(new Error('Page is required.'));
    if (typeof pageSize === 'undefined')
      return Promise.reject(new Error('Page size is required.'));

    return this.client.get(`/patient/${id}/participation`, {
      params: { page, pageSize, sortProp, sortDirection },
    });
  };

  getAllSimulatedPatients = ({
    fields,
    search,
    page,
    pageSize,
    isShowInactive,
    sortProp,
    sortDirection = 'ASC',
    criteria,
  } = {}) => {
    if (typeof search === 'undefined')
      return Promise.reject(new Error('Search is required.'));
    if (typeof page === 'undefined')
      return Promise.reject(new Error('Page is required.'));
    if (typeof pageSize === 'undefined')
      return Promise.reject(new Error('Page size is required.'));

    return this.client.post(
      '/patient/filter',
      { criteria },
      {
        params: {
          fields,
          isShowInactive,
          page,
          pageSize,
          search,
          sortProp,
          sortDirection,
        },
      },
    );
  };

  getAllSelectedSPs = (semesterId, { search, hideUnavailable }) => {
    if (typeof semesterId === 'undefined')
      return Promise.reject(new Error('Semester id is required.'));
    if (typeof search === 'undefined')
      return Promise.reject(new Error('Search is required.'));

    return this.client.get(`/patient/selectedSp/${semesterId}`, {
      params: { search, hideUnavailable },
    });
  };

  getNotInvitedSPs = (semesterId, { search, page, pageSize }) => {
    if (typeof semesterId === 'undefined')
      return Promise.reject(new Error('Semester id is required.'));
    if (typeof search === 'undefined')
      return Promise.reject(new Error('Search is required.'));
    if (typeof page === 'undefined')
      return Promise.reject(new Error('Page is required.'));
    if (typeof pageSize === 'undefined')
      return Promise.reject(new Error('Page size is required.'));

    return this.client.get(`/patient/not-invited/semester/${semesterId}`, {
      params: { search, page, pageSize },
    });
  };

  addSpToSemester = (selectedSpId, semesterId) => {
    if (typeof selectedSpId === 'undefined')
      return Promise.reject(new Error('SPs id is required.'));
    if (typeof semesterId === 'undefined')
      return Promise.reject(new Error('Semester id is required.'));

    return this.client.post(
      `/patient/${selectedSpId}/to/selectedSp/${semesterId}`,
    );
  };

  addAllSpToSemester = semesterId => {
    if (typeof semesterId === 'undefined')
      return Promise.reject(new Error('Semester id is required.'));
    return this.client.post(`/patient/active/to/SelectedSp/${semesterId}`);
  };

  removeSpFromSemester = (selectedSpId, semesterId) => {
    if (typeof selectedSpId === 'undefined')
      return Promise.reject(new Error('SPs id is required.'));
    if (typeof semesterId === 'undefined')
      return Promise.reject(new Error('Semester id is required.'));

    return this.client.patch(
      `/patient/selectedSp/${selectedSpId}/semester/${semesterId}`,
    );
  };

  getSelectedOSCEDays = semesterId => {
    if (typeof semesterId === 'undefined')
      return Promise.reject(new Error('Semester id is required.'));
    return this.client.get(`/patient/spSelection/${semesterId}`);
  };

  autoAssingSP = data => {
    if (typeof data === 'undefined')
      return Promise.reject(new Error('Data is required.'));
    return this.client.put('/patient/assignSp/auto', data);
  };

  assignSpForHalfDay = (osceDayId, assignSpForHalfDay) => {
    if (typeof osceDayId === 'undefined')
      return Promise.reject(new Error('OSCE day id is required.'));
    if (typeof assignSpForHalfDay === 'undefined')
      return Promise.reject(new Error('assignSpForHalfDay is required.'));

    return this.client.patch(`/osce/day/${osceDayId}`, null, {
      params: { assignSpForHalfDay },
    });
  };

  assignSpTo = data => {
    if (typeof data === 'undefined')
      return Promise.reject(new Error('Data is required.'));
    return this.client.post('/patient/selectSp', data);
  };

  assignSpToMultipleDays = (selectedSpId, data) => {
    if (typeof selectedSpId === 'undefined')
      return Promise.reject(new Error('SPs id is required.'));
    if (typeof data === 'undefined')
      return Promise.reject(new Error('Data is required.'));
    return this.client.post(`/patient/${selectedSpId}/to/osceDay`, data);
  };

  removeSpFromRole = data => {
    if (typeof data === 'undefined')
      return Promise.reject(new Error('Data is required.'));
    return this.client.post('/patient/select/delete', data);
  };

  filterSelectedSPsByRole = ({ semesterId, osceDayId, criteria }) => {
    if (typeof semesterId === 'undefined')
      return Promise.reject(new Error('Semester id is required.'));
    if (typeof osceDayId === 'undefined')
      return Promise.reject(new Error('OSCE day id is required.'));

    return this.client.post(
      `/patient/filter/semester/${semesterId}/osceday/${osceDayId}/selectedSp/`,
      { criteria },
    );
  };

  exportSp = criteria => {
    if (!criteria) return Promise.reject(new Error('Criteria are required.'));

    return this.client.post(
      `/patient/export`,
      { criteria },
      { responseType: 'arraybuffer' },
    );
  };

  getAllExaminers = ({
    search,
    page,
    pageSize,
    clinicId,
    sortProp,
    sortDirection = 'ASC',
  }) => {
    if (typeof search === 'undefined')
      return Promise.reject(new Error('Search is required.'));
    if (typeof page === 'undefined')
      return Promise.reject(new Error('Page is required.'));
    if (typeof pageSize === 'undefined')
      return Promise.reject(new Error('Page size is required.'));

    return this.client.get('/examiner', {
      params: {
        search,
        clinicId,
        page,
        pageSize,
        sortProp,
        sortDirection,
      },
    });
  };

  getAllClinics = ({
    search,
    page,
    pageSize,
    sortProp,
    sortDirection = 'ASC',
  }) => {
    if (typeof search === 'undefined')
      return Promise.reject(new Error('Search is required.'));
    if (typeof page === 'undefined')
      return Promise.reject(new Error('Page is required.'));
    if (typeof pageSize === 'undefined')
      return Promise.reject(new Error('Page size is required.'));

    return this.client.get('/clinic', {
      params: { search, page, pageSize, sortProp, sortDirection },
    });
  };

  getAllCatalogs = (isShowEnabledOnly = true) => {
    return this.client.get('/catalog', { params: { isShowEnabledOnly } });
  };

  getTargetSystemByCatalog = (catalogId, { search, page, pageSize }) => {
    if (typeof catalogId === 'undefined')
      return Promise.reject(new Error('Catalog id is required.'));
    if (typeof search === 'undefined')
      return Promise.reject(new Error('Search is required.'));
    if (typeof page === 'undefined')
      return Promise.reject(new Error('Page is required.'));
    if (typeof pageSize === 'undefined')
      return Promise.reject(new Error('Page size is required.'));

    return this.client.get(`/target-system/search/${catalogId}`, {
      params: { textForSearch: search, page, pageSize },
    });
  };

  addTargetSystemToRole = (targetSystemId, roleId) => {
    if (typeof targetSystemId === 'undefined')
      return Promise.reject(new Error('Target system id is required.'));
    if (typeof roleId === 'undefined')
      return Promise.reject(new Error('Role id is required.'));

    return this.client.post(`/target-system/${targetSystemId}/role/${roleId}`);
  };

  removeTargetSystemFromRole = (targetSystemId, roleId) => {
    if (typeof targetSystemId === 'undefined')
      return Promise.reject(new Error('Target system id is required.'));
    if (typeof roleId === 'undefined')
      return Promise.reject(new Error('Role id is required.'));

    return this.client.delete(
      `/target-system/${targetSystemId}/role/${roleId}`,
    );
  };

  getAllSemesters = () => {
    return this.client.get('/semester');
  };

  getAllOscesBySemester = (semesterId, { page, pageSize }) => {
    if (typeof semesterId === 'undefined')
      return Promise.reject(new Error('Semester id is required.'));
    if (typeof page === 'undefined')
      return Promise.reject(new Error('Page is required.'));
    if (typeof pageSize === 'undefined')
      return Promise.reject(new Error('Page size is required.'));

    return this.client.get(`/osce`, { params: { semesterId, page, pageSize } });
  };

  freezeOsce = (osceId, isFrozen) => {
    if (typeof osceId === 'undefined')
      return Promise.reject(new Error('OSCE id is required.'));
    if (typeof isFrozen === 'undefined')
      return Promise.reject(new Error('isFrozen is required.'));

    return this.client.patch(`/osce/${osceId}/isFreezed/${isFrozen}`);
  };

  getSimulatedPatientsByOsce = osceId => {
    if (typeof osceId === 'undefined')
      return Promise.reject(new Error('Osce id is required.'));
    return this.client.get(`/osce/${osceId}/sp`);
  };

  getStudentsByOsce = osceId => {
    if (typeof osceId === 'undefined')
      return Promise.reject(new Error('Osce id is required.'));
    return this.client.get(`/osce/${osceId}/student`);
  };

  getOsceStudentsResults = (
    osceId,
    { page, pageSize, sortProp, sortDirection = 'ASC' },
  ) => {
    if (typeof osceId === 'undefined')
      return Promise.reject(new Error('Osce id is required.'));
    if (typeof page === 'undefined')
      return Promise.reject(new Error('Page is required.'));
    if (typeof pageSize === 'undefined')
      return Promise.reject(new Error('Page size is required.'));

    return this.client.get(`/student/osce/${osceId}/results`, {
      params: { page, pageSize, sortProp, sortDirection },
    });
  };

  getOsceBellSystem = osceId => {
    if (typeof osceId === 'undefined')
      return Promise.reject(new Error('Osce id is required.'));

    return this.client.get(`/bellsystem/${osceId}`);
  };

  updateOsceBellSystem = osceId => {
    if (typeof osceId === 'undefined')
      return Promise.reject(new Error('Osce id is required.'));

    return this.client.get(`/bellsystem/update/${osceId}`);
  };

  removeStudentFromOSCE = (studentId, osceId) => {
    if (typeof studentId === 'undefined')
      return Promise.reject(new Error('Student id is required.'));
    if (typeof osceId === 'undefined')
      return Promise.reject(new Error('Osce id is required.'));
    return this.client.delete(`/student/${studentId}/${osceId}`);
  };

  getExaminersByOsce = osceId => {
    if (typeof osceId === 'undefined')
      return Promise.reject(new Error('Osce id is required.'));
    return this.client.get(`/osce/${osceId}/examiner`);
  };

  printExaminersSchedule = (osceId, locale, data) => {
    if (typeof osceId === 'undefined')
      return Promise.reject(new Error('Osce id is required.'));
    if (typeof locale === 'undefined')
      return Promise.reject(new Error('Locale is required.'));
    if (typeof data === 'undefined')
      return Promise.reject(new Error('Data is required.'));

    return this.client.post('/examiner/schedule/print', data, {
      params: { osceId, locale },
      responseType: 'arraybuffer',
    });
  };

  printSimulatedPatientsSchedule = (osceId, locale, data) => {
    if (typeof osceId === 'undefined')
      return Promise.reject(new Error('Osce id is required.'));
    if (typeof locale === 'undefined')
      return Promise.reject(new Error('Locale is required.'));
    if (typeof data === 'undefined')
      return Promise.reject(new Error('Data is required.'));

    return this.client.post('/patient/schedule/print', data, {
      params: { osceId, locale },
      responseType: 'arraybuffer',
    });
  };

  printStudentsSchedule = (osceId, locale, data) => {
    if (typeof osceId === 'undefined')
      return Promise.reject(new Error('Osce id is required.'));
    if (typeof locale === 'undefined')
      return Promise.reject(new Error('Locale is required.'));
    if (typeof data === 'undefined')
      return Promise.reject(new Error('Data is required.'));

    return this.client.post('/student/schedule/print', data, {
      params: { osceId, locale },
      responseType: 'arraybuffer',
    });
  };

  printStudentResults = (studentId, { osceId, locale, showAllAnswers }) => {
    if (typeof studentId === 'undefined')
      return Promise.reject(new Error('Student id is required.'));
    if (typeof osceId === 'undefined')
      return Promise.reject(new Error('Osce id is required.'));
    if (typeof locale === 'undefined')
      return Promise.reject(new Error('Locale is required.'));
    if (typeof showAllAnswers === 'undefined')
      return Promise.reject(new Error('Show All answers is required.'));

    return this.client.post(`/student/${studentId}/print`, null, {
      params: { osceId, locale, showAllAnswers },
      responseType: 'arraybuffer',
    });
  };

  printQRCode = (osceId, userType, data) => {
    if (typeof osceId === 'undefined')
      return Promise.reject(new Error('Osce id is required.'));
    if (typeof userType === 'undefined')
      return Promise.reject(new Error('User type is required.'));
    if (typeof data === 'undefined')
      return Promise.reject(new Error('Data is required.'));

    return this.client.post(
      `/schedule/osce/${osceId}/export/${userType}`,
      data,
      { responseType: 'arraybuffer' },
    );
  };

  downloadSpXLSX = semesterId => {
    return this.client.post(
      `/patient/export/spselection/semester/${semesterId}`,
      null,
      { responseType: 'arraybuffer' },
    );
  };

  downloadAudioNote = audioNoteId => {
    return this.client.get(`/note/${audioNoteId}`, {
      responseType: 'arraybuffer',
    });
  };

  sendEmailInvitation = (semesterId, userType, data) => {
    if (typeof semesterId === 'undefined')
      return Promise.reject(new Error('Semester id is required.'));
    if (typeof userType === 'undefined')
      return Promise.reject(new Error('User type is required.'));
    if (typeof data === 'undefined')
      return Promise.reject(new Error('Data is required.'));

    return this.client.post(`/${userType}/invite/send`, data, {
      params: { semesterId },
    });
  };
}

const api = new API();

export default api;
