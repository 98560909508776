import { find, cloneDeep, get, set, invoke } from 'lodash';
import { wrapRequest } from 'utils/ajax';

import { END_LOAD } from 'constants/actions';

import {
  osceCRUD,
  osceDayCRUD,
  circuitCRUD,
  roomCRUD,
  disciplineCRUD,
  postCRUD,
} from './cruds';

export const getOsceDays = osceId =>
  osceCRUD.getList({
    additionalUrl: `${osceId}/day`,
  });

export const getRooms = () => roomCRUD.getList();

export const getDiscipline = () => disciplineCRUD.getList();

export const getSequence = osceDayId =>
  osceDayCRUD.getList({
    additionalUrl: `${osceDayId}/sequence`,
  });

export const getCircuit = osceSequenceId =>
  circuitCRUD.getList({
    additionalUrl: `sequence/${osceSequenceId}`,
  });

export const addOsceDay = osceId =>
  wrapRequest({
    method: 'POST',
    url: `/osce/${osceId}/manual/day`,
  });

export const changeOsceDay = (osceDayId, data) =>
  wrapRequest({
    method: 'PUT',
    url: `/osce/day/${osceDayId}`,
    data,
  });

export const deleteOsceDay = osceDayId =>
  wrapRequest({
    method: 'DELETE',
    url: `/osce/day/${osceDayId}`,
  });

export const createCircuit = (osceSequenceId, data) =>
  wrapRequest({
    method: 'POST',
    url: `/circuit/${osceSequenceId}`,
    data,
  });

export const deleteCircuit = circuitId =>
  wrapRequest({
    method: 'DELETE',
    url: `/circuit/${circuitId}`,
  });

export const updatePost = param => body => postCRUD.patch(body, `/${param}`);

export const changeCircuitColor = (circuitId, data) =>
  wrapRequest({
    method: 'PUT',
    url: `/circuit/${circuitId}/color`,
    data,
  });

export const moveBreak = (timeSlotId, params) =>
  wrapRequest({
    method: 'PUT',
    url: `/move/time-slot/${timeSlotId}`,
    params,
  });

export const moveLunchBreak = (sequenceId, params) =>
  wrapRequest({
    method: 'PUT',
    url: `/move/lunch/osce-sequence/${sequenceId}`,
    params,
  });

export const moveRotation = (osceDayId, params) =>
  wrapRequest({
    method: 'PUT',
    url: `/move/rotation/osce-day/${osceDayId}`,
    params,
  });

export const addSequence = osceDayId =>
  wrapRequest({
    method: 'POST',
    url: `/osce-day/${osceDayId}/sequence/add`,
  });

export const updateSequence = (sequenceId, data) =>
  wrapRequest({
    method: 'PUT',
    url: `/osce-day/sequence/${sequenceId}`,
    data,
  });

export const deleteSequence = osceSequenceId =>
  wrapRequest({
    method: 'DELETE',
    url: `/osce-day/${osceSequenceId}`,
  });

export const splitOsceDayIntoSequence = osceDayId =>
  wrapRequest({
    method: 'POST',
    url: `/osce-day/${osceDayId}/split`,
  });

export const clearAllCircuit = osceId =>
  wrapRequest({
    method: 'DELETE',
    url: `/osce/${osceId}/clearAll`,
  });

export const addStation = (osceSequenceId, circuitId, data) =>
  wrapRequest({
    method: 'POST',
    url: `/station/osce-sequence/${osceSequenceId}/circuit/${circuitId}`,
    data,
  });

export const deleteStation = stationId =>
  wrapRequest({
    method: 'DELETE',
    url: `/station/${stationId}`,
  });

export const updateRoomRedux = (
  positionInOsceSequence,
  sequenceNumber,
  postIndex,
  room,
  osceSequenceId,
  sequenceData,
) => (
  // sequenceNumber will be changed in BE side
  dispatch,
  getState,
) => {
  const { runtime } = getState();
  sequenceData.forEach(async item => {
    let circuits = {};

    if (item.id) {
      if (item.id === osceSequenceId) {
        const circuitsData = get(runtime, `circuit${item.id}Data`);

        if (circuitsData) {
          circuits = cloneDeep(circuitsData);
          const circuit = find(circuits.data, { positionInOsceSequence });
          if (circuit) {
            const stationResponse = circuit.stationResponse.find(
              item => item.sequenceNumber === sequenceNumber,
            );
            set(
              stationResponse,
              `postResponses[${postIndex}].roomResponse`,
              room,
            );
          }
        }
      } else {
        const circuitsData = await getCircuit(item.id);
        circuits = circuitsData.data;
      }
      dispatch({
        type: END_LOAD,
        name: `circuit${item.id}`,
        data: circuits,
      });
    }
  });
};

export const updateDisciplineRedux = (
  positionInOsceSequence,
  sequenceNumber,
  postIndex,
  discipline,
  osceSequenceId,
  sequenceData,
) => (
  // sequenceNumber will be changed in BE side
  dispatch,
  getState,
) => {
  const { runtime } = getState();
  sequenceData.forEach(async item => {
    let circuits = {};
    if (item.id) {
      if (item.id === osceSequenceId) {
        const circuitsData = get(runtime, `circuit${item.id}Data`);

        if (circuitsData) {
          circuits = cloneDeep(circuitsData);
          circuits.data.forEach(circuit => {
            const stationResponse = circuit.stationResponse.find(
              item => item.sequenceNumber === sequenceNumber,
            );
            stationResponse.postResponses.forEach(post => {
              set(post, 'disciplineResponse', discipline);
              set(post, 'skillResponse', undefined);
              set(post, 'roleResponse', undefined);
            });
          });
        }
      } else {
        const circuitsData = await getCircuit(item.id);
        circuits = circuitsData.data;
      }
      dispatch({
        type: END_LOAD,
        name: `circuit${item.id}`,
        data: circuits,
      });
    }
  });
};

export const updateSkillRedux = (
  positionInOsceSequence,
  sequenceNumber,
  postIndex,
  skill,
  osceSequenceId,
  sequenceData,
) => (
  // sequenceNumber will be changed in BE side
  dispatch,
  getState,
) => {
  const { runtime } = getState();
  sequenceData.forEach(async item => {
    let circuits = {};

    if (item.id) {
      if (item.id === osceSequenceId) {
        const circuitsData = get(runtime, `circuit${item.id}Data`);

        if (circuitsData) {
          circuits = cloneDeep(circuitsData);
          circuits.data.forEach(circuit => {
            const stationResponse = circuit.stationResponse.find(
              item => item.sequenceNumber === sequenceNumber,
            );
            stationResponse.postResponses.forEach(post => {
              set(post, 'skillResponse', skill);
              set(post, 'roleResponse', undefined);
            });
          });
        }
      } else {
        const circuitsData = await getCircuit(item.id);
        circuits = circuitsData.data;
      }
      dispatch({
        type: END_LOAD,
        name: `circuit${item.id}`,
        data: circuits,
      });
    }
  });
};

export const updateRoleRedux = (
  positionInOsceSequence,
  sequenceNumber,
  postIndex,
  role,
  sequenceId,
) => (
  // sequenceNumber will be changed in BE side
  dispatch,
  getState,
) => {
  const { runtime } = getState();
  const circuits = get(runtime, `circuit${sequenceId}Data`);
  if (circuits) {
    const { data } = cloneDeep(circuits);
    data.forEach(circuit => {
      const stationResponse = circuit.stationResponse.find(
        item => item.sequenceNumber === sequenceNumber,
      );
      set(stationResponse, `postResponses[${postIndex}].roleResponse`, role);
    });
    dispatch({
      type: END_LOAD,
      name: `circuit${sequenceId}`,
      data: { data },
    });
  }
};

export const validateRooms = (
  positionInOsceSequence,
  sequenceNumber,
  postIndex,
  roomId,
  osceSequenceId,
) => (
  // sequenceNumber will be changed in BE side
  dispatch,
  getState,
) => {
  const { runtime } = getState();
  let hasSameRoom = null;
  const data = get(runtime, `circuit${osceSequenceId}Data.data`);
  data.forEach((circuit, circuitIndex) => {
    invoke(circuit, 'stationResponse.forEach', (station, stationIndex) => {
      invoke(station, 'postResponses.forEach', (post, postIndex) => {
        if (get(post, 'roomResponse.id') === roomId) {
          hasSameRoom = {
            station,
            stationPosition: stationIndex + 1,
            positionInOsceSequence: circuitIndex + 1,
            postIndex,
          };
        }
      });
    });
  });
  return hasSameRoom;
};
