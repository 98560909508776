import qs from 'qs';
import { wrapRequest } from 'utils/ajax';
import dispatchSend from 'services/dispatchSend';
import { formatDateStr, formatTimeStr, checkValue } from 'services/format';

import { osceCRUD } from './cruds';

export const getOsceDefaultProfile = () =>
  dispatchSend(
    'osceDefault',
    wrapRequest({
      method: 'GET',
      url: '/sftp/default',
    }),
  );

export const getOsceCustomProfile = id =>
  dispatchSend(
    'osceCustom',
    wrapRequest({
      method: 'GET',
      url: `/sftp/${id}/custom`,
    }),
  );

export const postCustomOsceSetting = (id, data) =>
  wrapRequest({
    method: 'POST',
    url: `/sftp/${id}`,
    data,
  });

export const getOsceById = id => osceCRUD.get(id);

export const updateOsce = data => {
  checkValue(data, 'osceDate', formatDateStr);
  checkValue(data, 'startTime', formatTimeStr);
  checkValue(data, 'endTime', formatTimeStr);
  const isAuto = data.OSCECreationType === 'AUTO';
  if (isAuto) {
    data.osceType = 'AUTO';
  } else {
    data.OSCECreationType = 'MANUAL';
    data.osceType = 'MANUAL';
  }
  return wrapRequest({
    method: 'PUT',
    url: `/osce/${data.id}/${isAuto ? 'auto' : 'manual'}`,
    data,
  });
};

export const addNewOsce = data =>
  wrapRequest({
    method: 'POST',
    url: `/osce/auto`,
    data,
  });

export const exportToiOsce = params =>
  wrapRequest({
    method: 'POST',
    url: '/iosce/export',
    params,
    paramsSerializer: data => qs.stringify(data, { arrayFormat: 'repeat' }),
  });

export const downloadOsce = params =>
  wrapRequest({
    method: 'POST',
    url: '/iosce/download',
    params,
    paramsSerializer: data => qs.stringify(data, { arrayFormat: 'repeat' }),
  });

export const importFileFromIOsce = params =>
  wrapRequest({
    method: 'POST',
    url: '/iosce/import',
    params,
    paramsSerializer: data => qs.stringify(data, { arrayFormat: 'repeat' }),
  });

export const generateCircuits = osceId =>
  wrapRequest({
    method: 'POST',
    url: `/circuit/generate/${osceId}`,
  });

export const generateCircuitsManual = osceId =>
  wrapRequest({
    method: 'POST',
    url: `/circuit/generate/manual/osce/${osceId}`,
  });

export const deleteOsce = osceId => osceCRUD.deleteRequest(osceId);

export const getAllOsces = semesterId =>
  osceCRUD.getList({
    params: { semesterId },
  });

export const genaratePreview = id =>
  wrapRequest({
    method: 'GET',
    url: `/osce/${id}/preview/auto`,
  });

export const calculateManualOsce = id =>
  wrapRequest({
    method: 'POST',
    url: `/osce/${id}/calculate/manual`,
  });

export const exportOsceStatistic = (route, params) =>
  wrapRequest({
    method: 'POST',
    url: `/osce/statistic/${route}`,
    params,
    paramsSerializer: data => qs.stringify(data, { arrayFormat: 'repeat' }),
    responseType: 'arraybuffer',
  });

export const getCostAccounting = (params, data) =>
  wrapRequest({
    method: 'POST',
    url: '/patient/print/billing',
    params,
    data,
    paramsSerializer: data => qs.stringify(data, { arrayFormat: 'repeat' }),
    responseType: 'arraybuffer',
  });
