import { get, set, invoke } from 'lodash';

function handleSubmit(e, form) {
  e && e.preventDefault();
  return new Promise((resolve, reject) => {
    form.validateFields((err, values) => (err ? reject(err) : resolve(values)));
  });
}

export const setFocusInTheEnd = refInput => {
  invoke(refInput, 'focus');
  let namePath;
  if (get(refInput, 'input')) namePath = 'input';
  if (get(refInput, 'inputNumberRef.input')) namePath = 'inputNumberRef.input';
  const value = get(refInput, `${namePath}.value`);
  if (value) {
    set(refInput, `${namePath}.value`, '');
    set(refInput, `${namePath}.value`, value);
  }
};

export const openDatePicker = ref => invoke(ref, 'picker.input.click');
export const openTimePicker = ref => invoke(ref, 'timePickerRef.picker.click');

export const getInitialValueForCheckBoxGroup = (
  namesArray = [],
  fromObject = {},
) =>
  namesArray.map(name => get(fromObject, name) && name).filter(value => value);

export default handleSubmit;
